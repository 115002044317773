import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Users from "./Users";

const Admin = () => {
    const [activeTab, setActiveTab] = useState("users");

    return (
        <Users/>
    )
}

const mapStateToProps = state => ({
    user: state.auth.module.user,
    pathname: state.router.location.pathname,
});

const connected = connect(mapStateToProps, {})(Admin);

export default withRouter(connected);
