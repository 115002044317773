import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {getUser, getUsersRequest, resetUserInState} from "./actions";
import User from "./User";
import Modal from "react-bootstrap/Modal";
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import {showError} from "../../common/lib/show-toast";
import MaterialTable from "material-table";
import {Fab} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import styled from 'styled-components';
import {ButtonBlack} from "../../common/components/color-button";

const _ = require('underscore');

const userTableColumns = [
    {
        title: "Fornavn",
        field: "firstName",
    },
    {
        title: "Etternavn",
        field: "lastName",
    },
    {
        title: "E-post",
        field: "email",
    },
    {
        title: "Roller",
        field: "roles",
    }
];

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: 30px;
  right: 30px;

`

const fabGreenStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
  color: 'common.white',
  bgcolor: '#999'
};

const Users = ({}) => {
    const [userId, setUserId] = useState(null);
    const [fixedData, setFixedData] = useState({
        columns: userTableColumns,
        rows: []
    });

    const fetchUsers = async () => {
        try {
            await Fetch(API_BASE_URL + "/admin/users", "GET", {}).then(result => {
                manageServerUserData(result.data);
            })
        } catch (error) {
            showError(error);
        }
    }

    useEffect( () => {
        fetchUsers();
    }, []);

    const manageServerUserData = (data) => {
        let rows = [];
        if (_.isArray(data)) {
            for (let row of data) {
                const id = row.id;
                let r = {
                    firstName: row.firstName,
                    lastName: row.lastName,
                    email: row.email,
                    roles: row.roles.map((role) => role.roleName).join(", "),
                    id
                };
                rows.push(r);
            }
        }
        setFixedData({...fixedData, rows: rows});
    };

    const addUser = () => {
        setUserId(-1);
    };

    const onFinished = () => {
        setUserId(null);
        fetchUsers();
    }

    return (
        <div>
            <div>
                <MaterialTable title={"Brukere"} columns={userTableColumns} data={fixedData.rows} onRowClick={(event, rowData, togglePanel) => setUserId((rowData.id))}/>
              <StyledFab onClick={addUser} color="primary" aria-label="add">
                <Add />
              </StyledFab>
            </div>
            <Modal show={userId!==null} onHide={() => setUserId(null)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Bruker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <User userId={userId} onFinished={onFinished}/>
                </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-around">
                    <ButtonBlack onClick={() => setUserId(null)} className="blue-gradient">
                        Lukk
                    </ButtonBlack>
                </div>
            </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.admin.user.user,
    pathname: state.router.location.pathname,
});

const connected = connect(mapStateToProps, {getUsersRequest, getUser, resetUserInState})(Users);

export default withRouter(connected);
