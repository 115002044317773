
import React from "react";
import {TextField} from "@material-ui/core";

const GITextInput = ({label, name, value, handleChange, valid}) => {

    return (
        <TextField
            className={valid ? "form-control is-invalid" : "form-control is-valid"}
            type="text"
            label={label}
            name={name}
            value={value}
            onChange={handleChange}
        />
    )
};


export default GITextInput;
