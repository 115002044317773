import React, {Component} from 'react';
import './App.css';
import Dashboard from "./components/dashboard";
import {Route, withRouter} from "react-router-dom";
import Login from "./components/auth/login/AuthLogin";
import {PrivateRoute} from "./components/PrivateRoute";
import Header from "./components/header/Header";
import {Redirect, Switch} from "react-router";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {loggedInRequest} from "./actions";
import Admin from "./components/admin";
import Voting from "./components/voting";
import VotingResult from "./components/voting/VotingResult";
import SetupVoting from "./components/voting/SetupVoting";
import ResetPassword from "./components/auth/login/ResetPassword";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CookieConsent from "react-cookie-consent";
import {GDPR} from "./components/user/gdpr";
import UserHome from "./components/user/UserHome";
import Reload from "./modules/reload/Reload";
import {Toaster} from "react-hot-toast";
import UploadResult from "./components/voting/UploadResult";
import YearPoints from "./components/voting/YearPoints";

class App extends Component {
  constructor(props) {
    super(props);

    const user = localStorage.getItem("auth");
    if (user) {
      this.props.loggedInRequest();
    }
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: '',
      sideNavToggled: false,
      breakWidth: 1400
    };
  }

  render() {

    return (
      <>
        <Reload/>
        <CookieConsent
          location="bottom"
          buttonText="Jeg godtar"
          cookieName="letphotoCookieConsent"
          style={{background: "#2B373B"}}
          buttonStyle={{background: "#000000", color: "#ffff", fontSize: "13px"}}
          expires={150}
        >
          Denne websiden bruker cookies for å håndtere bruker-innlogging.{" "}
          <span>Les vår <a href="/personvern" className={"grey-text"}>personvernsærklæring</a></span>
        </CookieConsent>
        <Toaster/>
        <Switch>
          <Route exact path="/"><Redirect to="/dashboard"/></Route>
          <Route path="/login" exact component={Login}/>
          <Route path="/resetPassword/:token" exact component={ResetPassword}/>
          <Route path="/personvern" exact component={GDPR}/>
          <React.Fragment>
            <PrivateRoute path="/" component={Header}/>
            <PrivateRoute path="/dashboard" exact component={Dashboard}/>
            <PrivateRoute path="/setup/:votingId" exact component={SetupVoting}/>
            <PrivateRoute path="/admin" component={Admin}/>
            <PrivateRoute path="/user/:id" exact component={UserHome}/>
            <PrivateRoute path="/points" exact component={YearPoints}/>
            <PrivateRoute path="/vote/:votingId" exact component={Voting}/>
            <PrivateRoute path="/result/:votingId" exact component={VotingResult}/>
            <PrivateRoute path="/result/upload/:votingId" exact component={UploadResult}/>
          </React.Fragment>
        </Switch>
      </>
    );
  }
}

App.propTypes = {
  loggedInRequest: PropTypes.func
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {loggedInRequest})(App);

export default withRouter(connected);
