import {connect} from "react-redux";
import {withRouter} from "react-router";
import React, {useState} from "react";

import {API_BASE_URL} from "../../../constants";
import {Fetch} from "../../../common/lib/api-fetch";
import {showInfo} from "../../../common/lib/show-toast";
import TextField from "@material-ui/core/TextField";
import {ButtonBlack} from "../../../common/components/color-button";
import styled from "styled-components";
import {Card} from "react-bootstrap";

const StyledLogin = styled.div`
  background-color: #000;
`;

const StyledCard = styled(Card)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px 20px 20px 20px;
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    width: 90%;
  }
  background-color: #FFF;
`;

const StyledCardHeader = styled(Card.Header)`
  text-align: center;
`

const StyledCardBody = styled(Card.Body)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ResetPassword = ({history, match}) => {
  const [form, setForm] = useState({
    email: {changed: false, value: ''},
    newPassword: {changed: false, value: ''},
    newPassword2: {changed: false, value: ''}
  });

  const token = match.params.token;

  const submit = async (event) => {
    event.preventDefault();
    try {
      await Fetch(API_BASE_URL + "/auth/resetPassword", 'POST', {
        token: token,
        password: form.newPassword.value
      }).then(result => {
        showInfo("Passordet ditt er endret");
        history.push("/login");
      })
    } catch (error) {
      showInfo("Noe gikk galt i endringen av passordet");
    }
  };

  const changeHandler = event => {
    setForm({
      ...form,
      [event.target.name]: {changed: true, value: event.target.value}
    })
  };

  return (
    <StyledLogin>
      <form className="needs-validation" onSubmit={submit} noValidate>
        <StyledCard>
          <StyledCardHeader>
            <h3>Sett passordet:</h3>
          </StyledCardHeader>
          <StyledCardBody>
            <TextField
              id="newPassword"
              label="Passord"
              name={"newPassword"}
              placeholder="Passord"
              margin="normal"
              variant="outlined"
              type="password"
              value={form.newPassword.value}
              onChange={changeHandler}
              minLength={4}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <div className={"ml-3 small text-danger " + (form.newPassword.value.length >= 4 ? "d-none" : "")}>Må være 4
              tegn eller lengre
            </div>
            <TextField
              id="newPassword2"
              label="Passordet igjen"
              name={"newPassword2"}
              placeholder="Passordet igjen"
              margin="normal"
              variant="outlined"
              type="password"
              value={form.newPassword2.value}
              onChange={changeHandler}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <div
              className={"ml-3 small text-danger " + (form.newPassword.value === form.newPassword2.value ? "d-none" : "")}>Må
              være lik
            </div>
            <div className="text-center mt-3 black-text">
              <ButtonBlack
                type="submit"
                disabled={!form.newPassword.changed || !form.newPassword2.changed || form.newPassword.value !== form.newPassword2.value || form.newPassword.value.length < 4}
              >Endre passord</ButtonBlack>
            </div>

          </StyledCardBody>
        </StyledCard>
      </form>
    </StyledLogin>
  )
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {})(ResetPassword);

export default withRouter(connected);
