import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getImageUrl} from "../../common/lib/image";
import {connect} from "react-redux";
import {hideHeader, showHeader} from "../header/actions";
import {withRouter} from "react-router";
import Box from "@material-ui/core/Box";
import './index.scss';
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {ButtonBlack} from "../../common/components/color-button";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'black'
  },

}));

const VotingBestof = ({voteData, history}) => {
  const classes = useStyles();
  const [images, setCurrentImages] = useState([]);
  const [vote, setVote] = useState(null);
  const [voted, setVoted] = useState(false);

  useEffect(() => {
    setCurrentImages(voteData.votingImages);
  }, [voteData]);

  useEffect(() => {
    if (images) {
      for(const image of images) {
        if (image.answer) {
          setVote(image.id);
          setVoted(true);
        }
      }
    }
  }, [images]);

  const sendVote = async () => {
    await Fetch(API_BASE_URL+"/voting/vote", "POST", {votingId: voteData.id, vote: vote}).then(result => {
      history.push("/dashboard");
    })
  }

  return (
    <>
        <Box width={1} className={classes.root}>
        <Container className={classes.root}>
          <Row className="align-content-center">
            { images.map(image =>
            <Col key={image.id} className="m-4">
              <Row>
                <img className={"vote-result img-fluid shadow"+(image.id===vote ? " border-chosen" : "")} src={getImageUrl(image)} alt={image.title}
                     onClick={() => setVote(image.id)}
                />
              </Row>
            </Col>
            )}
          </Row>
          <Row className="justify-content-center">
            <ButtonBlack color={"dark"} className={"white-text"} disabled={vote===null ||voted} onClick={sendVote}>{voted ? "Du har stemt" : "Stem"}</ButtonBlack>
          </Row>
        </Container>
        </Box>
    </>
  )
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {hideHeader, showHeader})(VotingBestof);

export default withRouter(connected);
