import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import React, {useEffect, useState} from "react";
import {formatDate} from "../../common/lib/date";
import Tooltip from "@material-ui/core/Tooltip";
import {connect} from "react-redux";
import {setCurrentEvaluation} from "./actions";
import {withRouter} from "react-router";
import {ButtonBlack} from "../../common/components/color-button";
import {API_BASE_URL} from "../../constants";
import {AppRoles} from '../../constants';

import _ from 'lodash';
import {Fetch} from "../../common/lib/api-fetch";
import {withStyles} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";

const SmallAvatar = withStyles(theme => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const TypeImages = {
  1: '/system/color.jpg',
  2: '/system/black-white.jpg',
  3: '/system/print.jpg',
  4: '/system/best.jpg',
  5: '/system/all.jpg',
};

const TypeText = {
  1: 'Farge',
  2: 'Sort/Hvitt',
  3: 'Print',
  5: 'Alle'
}

const Evaluation = ({evaluation, user, history, setEvaluation, images, imageCount, groupCount}) => {
  const [voting, setVoting] = useState(null);
  const now = Date.now();
  const [reachedStartDate, setReachedStartDate] = useState(null);
  const [reachedVoteDate, setReachedVoteDate] = useState(null);
  const [reachedEndOfVoteDate, setReachedEndOfVoteDate] = useState(null);
  const [reachedResultDate, setReachedResultDate] = useState(null);
  const [reachedBestofResultDate, setReachedBestofResultDate] = useState(null);
  const [openForPresenter, setOpenForPresenter] = useState(false);
  const [voteFinished, setVoteFinished] = useState(false);
  const [reachedStopUploadDate, setReachedStopUploadDate] = useState(null);

  useEffect( () => {
    Fetch(API_BASE_URL + "/voting/" + evaluation.id + "/voteFinished", "GET").then(result => {
      setVoteFinished(result.data.data);
    })
  }, [voting])

  useEffect(() => {
    if (voting !== null) {
      setReachedStartDate(now > new Date(voting.uploadDate) && now < new Date(voting.voteStartDate));
      setReachedVoteDate(now > new Date(voting.voteStartDate) && now < new Date(voting.voteStopDate));
      setReachedEndOfVoteDate(now > new Date(voting.voteStopDate));
      setReachedResultDate(now > new Date(voting.resultDate));
      setReachedStopUploadDate(now > new Date(voting.stopUploadDate));

      if ('bestof' in voting && voting.bestof.length > 0) {
        let maxResultDate = null;
        for (const bestof of voting.bestof) {
          const rDate = new Date(bestof.bestof.voteStopDate);
          if (rDate > maxResultDate) {
            maxResultDate = rDate;
          }
        }
        setReachedBestofResultDate(maxResultDate < now);
      }
    }
  }, [voting]);

  useEffect(() => {
    if (reachedEndOfVoteDate)
      setOpenForPresenter(user.roles.some((role) => role.roleName === AppRoles.PRESENTER));
  }, [reachedEndOfVoteDate]);

  const doVote = () => {
    history.push("/vote/" + evaluation.id);
  }

  const doResult = () => {
    history.push("/result/" + evaluation.id);
  }

  const finishVoting = () => {
    Fetch(API_BASE_URL + "/voting/" + evaluation.id + "/stopVoting", "POST", {}).then(result => {
      setVoting(result.data);
    })
  }

  const startVoting = () => {
    Fetch(API_BASE_URL + "/voting/" + evaluation.id + "/startVoting", "POST", {}).then(result => {
      setVoting(result.data);
    })
  }

  useEffect(() => {
    setVoting(_.cloneDeep(evaluation));
  }, [evaluation]);

  const BestOfImages = () => {
    let bestofReachedResultDate = false;

    return (
      <>
        <span>
            Månedens bilde<br/>
            Intervall for stemming: {formatDate(voting.voteStartDate)} - {formatDate(voting.voteStopDate)}<br/>
            Resultat: {formatDate(voting.resultDate)}
        </span>
        <div className="mt-2">
          {user.roles.some(role => role.roleName === AppRoles.MEMBER) &&
            <Tooltip
              title={!reachedVoteDate && !reachedEndOfVoteDate ? "Åpner: " + formatDate(voting.voteStartDate) : ""}
              placement={"top"}>
                    <span>
                      <ButtonBlack
                        variant={"contained"}
                        disabled={!reachedVoteDate || reachedResultDate || !reachedBestofResultDate}
                        onClick={doVote}>
                        Stem {voteFinished && " (Ferdig)"}
                      </ButtonBlack>
                    </span>
            </Tooltip>
          }
          {user.roles.some(role => role.roleName === AppRoles.PRESENTER) && !reachedEndOfVoteDate &&
          <ButtonBlack
                       variant="contained"
                       disabled={!reachedVoteDate}
                       onClick={finishVoting}>
            Stopp avstemming
          </ButtonBlack>
          }
          <Tooltip title={!reachedResultDate ? "Åpner: " + formatDate(voting.resultDate) : ""}
                   placement={"top"}>
              <span>
            <ButtonBlack variant={"contained"} disabled={!reachedResultDate && !openForPresenter}
                         onClick={doResult}>Se resultat</ButtonBlack>
              </span>
          </Tooltip>
          {
            voting.ownerId === user.id && !reachedResultDate &&
            <ButtonBlack variant="contained"
                         onClick={() => history.push("/setup/" + voting.id)}>Rediger</ButtonBlack>
          }

        </div>
      </>
    )
  }

  const UploadCategory = () => {
    return (
      <>
        <span>
              Tema: {voting.theme} {voting.themePoints > 0 ? "(" + voting.themePoints + ")" : ""}<br/>
              Type: Opplasting / {TypeText[voting.typeId]}<br/>
              Antall bilder: {images ? images.length : 0}/{voting.imageCount}<br/>
              Bilder til nå: {imageCount}<br/>
              Start for opplasting av bilder: {formatDate(voting.uploadDate)}<br/>
              Slutt på opplasting av bilder: {formatDate(voting.stopUploadDate)}<br/>
        </span>
        <div className="mt-2">
          {user.roles.some(role => role.roleName === AppRoles.MEMBER) &&
          <>
          <span>
                  <ButtonBlack onClick={() => setEvaluation(voting)}
                               variant="contained"
                               disabled={reachedStopUploadDate}>
                    Send inn bilder
                  </ButtonBlack>
          </span>
          </>
          }
          <span>
                  <ButtonBlack onClick={() => history.push("/result/upload/"+voting.id)}
                               variant="contained">
                    Se bilder
                  </ButtonBlack>
          </span>
           {
             (voting.ownerId === user.id || user.roles.some(role => role.roleName === AppRoles.ADMIN)) &&
            <ButtonBlack variant="contained"
                         onClick={() => history.push("/setup/" + voting.id)}>Rediger</ButtonBlack>
          }
        </div>
      </>
    )
  }

  const Secondary = () => {
    return (
      <>
        <span>
              Tema: {voting.theme} {voting.themePoints > 0 ? "(" + voting.themePoints + ")" : ""}<br/>
              Type: Konkurranse / {TypeText[voting.typeId]}<br/>
              Antall bilder: {images ? images.length : 0}/{voting.imageCount}<br/>
          {groupCount!==null && <>
              Gruppe: {evaluation.group_name}<br/>
              Antall bilder i gruppe: {groupCount} / 2<br/>
              </>}
              Bilder til nå: {imageCount}<br/>
              Intervall for opplasting: {formatDate(voting.uploadDate)} - {formatDate(voting.stopUploadDate)}<br/>
              Intervall for stemming: {formatDate(voting.voteStartDate)} - {formatDate(voting.voteStopDate)}<br/>
              Resultat: {formatDate(voting.resultDate)}<br/>
          {voting.themePoints > 0 &&
          <>
            Poeng for tema: {voting.themePoints}<br/>
          </>
          }
        </span>
        <div className="mt-2">
          {user.roles.some(role => role.roleName === AppRoles.MEMBER) &&
          <>
          <span>
                  <ButtonBlack onClick={() => setEvaluation(voting)}
                               variant="contained"
                               disabled={reachedStopUploadDate || (groupCount===2 && (!images || images.length===0))}>
                    { images && images.length===voting.imageCount ? 'Endre bilde(r)' : 'Send inn bilde(r)' }
                  </ButtonBlack>
                  <Tooltip
                    title={!reachedVoteDate && !reachedEndOfVoteDate ? "Åpner: " + formatDate(voting.voteStartDate) : ""}
                    placement={"top"}>
                    <span>
                      <ButtonBlack
                        variant={"contained"}
                        disabled={!reachedVoteDate || reachedResultDate}
                        onClick={doVote}>
                        Stem { voteFinished && " (Ferdig)"}
                      </ButtonBlack>
                    </span>
                  </Tooltip>
          </span>
          </>
          }
          <Tooltip title={!reachedResultDate ? "Åpner: " + formatDate(voting.resultDate) : ""}
                   placement={"top"}>
              <span>
            <ButtonBlack variant={"contained"} disabled={!reachedResultDate && !openForPresenter}
                         onClick={doResult}>Se resultat</ButtonBlack>
              </span>
          </Tooltip>
          {
            voting.ownerId === user.id && !reachedResultDate &&
            <ButtonBlack variant="contained"
                         onClick={() => history.push("/setup/" + voting.id)}>Rediger</ButtonBlack>
          }
          {
            voting.ownerId === user.id && reachedVoteDate && !reachedResultDate &&
            <ButtonBlack variant="contained" onClick={finishVoting}>Stopp avstemming</ButtonBlack>
          }
          {
            voting.ownerId === user.id && !reachedVoteDate && !reachedResultDate &&
            <ButtonBlack variant="contained" onClick={startVoting}>Start avstemming</ButtonBlack>
          }
        </div>
      </>
    )
  }

  return (
    <>
      {voting !== null ?
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            {voting.categoryId === 1 &&
            <Avatar alt="Om bilde" src={TypeImages[voting.typeId]} className={"shadow"}/>
            }{voting.categoryId === 3 &&
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<SmallAvatar alt="Ikke konkurranse" src={TypeImages[voting.typeId]} />}
            >
              <Avatar alt="Om bilde" src={TypeImages[voting.typeId]} className={"shadow"}/>
            </Badge>
              }
          </ListItemAvatar>
          <ListItemText
            component="span"
            primary={voting.title}
            secondary={
              voting.typeId === 4 ? <BestOfImages/> : voting.categoryId=== 3 ? <UploadCategory/> : <Secondary/>
            }
          />
        </ListItem>
        : null
      }
    </>
  )
};

const mapStateToProps = state => ({
  user: state.user.user
});

const connected = connect(mapStateToProps, {setCurrentEvaluation})(Evaluation);

export default withRouter(connected);
