import React from "react";
import {toast} from "react-hot-toast";

export const showError = (error) => {
    toast(<span>Feil: {error.toString().substr(7)}</span>)
}

export const showInfo = (info) => {
  toast(<span>{info}</span>);
}

export const showErrorText = (text) => {
  toast(<span>Feil: {text}</span>)
}
