import React from "react";
import Header from "../header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh'
  }
}));

export const GDPR = () => {
  const classes = useStyles();
  return (
    <>
      <Header></Header>
      <Container className={classes.root}>
        <Row className="justify-content-center" md={8}>
          <Container>
            <Row className="justify-content-center mt-3">
              <Col>
                <h3>Personvernærklæring</h3>
                <b>Foto</b> lagrer kun data som er relevant for at du skal kunne
                være med i konkurranser og å stemme på dem.
                <h4 className={"mt-4"}>Informasjonskapsler</h4>
                <b>Foto</b> bruker informasjonskapsler til å vite at du er logget inn
                Utover det lages ingen informasjonskapsler.
                <h4 className={"mt-4"}>E-postkommmunikasjon</h4>
                Ingen e-poster som sendes ut lagres i systemet.
                <h4 className={"mt-4"}>SSL-kryptering</h4>
                Når du går inn på <b>Foto</b> startes det automatisk en sikker (SSL-kryptert) sesjon.
                Du kan kontrollere dette (at sidene er kryptert: se etter https)
                ved å klikke på hengelåsikonet som vises i din nettleser.
                <h4 className={"mt-4"}>Personlige opplysninger</h4>
                Det lagres minimalt med personlige opplysninger. Men informasjon knyttet til
                poenget med tjenesten vil bli lagret, som informasjon om bilder du laster opp
                og hva du stemmer.
                <h4 className={"mt-4"}>Innsyn og sletting</h4>
                Du kan sende epost til Vegard.Hanssen@menneske.no for å få slettet dataene dine.
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  )
}
