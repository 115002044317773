import React, {useEffect, useState} from "react";

import './UploadResult.scss';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Container from "@material-ui/core/Container";
import {getImageUrl, ImageBox} from "../../common/lib/image";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {ButtonBlack} from "../../common/components/color-button";

const UploadResult = ({match, history}) => {
  const votingId = parseInt(match.params.votingId);
  const [voteData, setVotingData] = useState(null);
  const [images, setImages] = useState(null);

  const fetchVotingData = async () => {
    await Fetch(API_BASE_URL + "/voting/" + votingId + "/voteImages", 'GET', {}).then(result => {
      setVotingData(result.data);
      setImages(result.data.images);
    })
  };

  useEffect(() => {
    fetchVotingData();
  }, [votingId]);

  return (
    <>
      <div style={{backgroundColor: 'black'}}>
        <Container maxWidth={"md"}>
          <div className="flex-center">
          <ButtonBlack className="mt-2" onClick={() => history.push("/dashboard")}
                       variant="contained">
            Tilbake
          </ButtonBlack>
          </div>
          <div className="upload-container">
            {images && images.map(image =>
              <div key={image.uuid}>
                <ImageBox path={getImageUrl(image)}/>
                <div className="text">
                  <span>Tittel: {image.title}</span><br/>
                  <span>Av: {image.fullname}</span>
                </div>
              </div>
            )}
            {(!images || images.length===0) && <div>Ingen bilder registrert</div>}
          </div>
        </Container>
      </div>
    </>
  )
}


const mapStateToProps = state => ({
  user: state.user.user
});

const connected = connect(mapStateToProps, {})(UploadResult);

export default withRouter(connected);
