import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router";
import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';
import {Link} from 'react-router-dom';
import {API_BASE_URL} from "../../constants";
import {logoutRequest} from "../auth/actions";
import './index.scss';
import Gravatar from 'react-gravatar';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  menuUser: {
    [theme.breakpoints.down('sm')]: {
      padding: '.84rem 2.14rem'
    }
  }
}));

const UserMenu = ({user}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.menuUser}>
      <Gravatar onClick={() => history.push("/user/"+user.id)} className="gravatar" size={30} email={user.email}/>
    </div>
  );
}

const Header = ({user, header, logoutRequest}) => {
  const [logo, setLogo] = useState("");

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="/dashboard">
          <img src="/system/bfk-logo.gif" alt={"Logo"} style={{height: '30px'}}
               className="img-fluid"/>
          <span className={"vl mx-1"}/>
          {logo && logo !== "" &&
          <img className="mb-2" src={API_BASE_URL + "/image/" + logo} style={{height: '30px'}} className="img-fluid"/>
          }
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {
              user &&
              <>
                <Nav.Link as={Link} to="/dashboard">Hjem</Nav.Link>
                <Nav.Link as={Link} to="/points">Årspoeng</Nav.Link>
              </>
            }
            {
              user && user.roles.some(obj => obj.roleName === 'ADMIN') &&
              <Nav.Link as={Link} to="/admin">Brukere</Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
        {user &&
        <Navbar.Collapse id="right-navbar">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/personvern">Personvern</Nav.Link>
            <div className="ml-4"></div>
            <UserMenu user={user}/>
            <div className="ml-4"></div>
            <Nav.Link onClick={() => logoutRequest()}>Logg ut</Nav.Link>
            <div className="ml-4"></div>
          </Nav>
        </Navbar.Collapse>
        }
      </Navbar>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.auth.module.user,
  header: state.header
});

const connected = connect(mapStateToProps, {logoutRequest})(Header);

export default withRouter(connected);
